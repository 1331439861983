@import 'fonts/greycliff.css';
@import 'fonts/inter.css';

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: 62.5%;
}

.App {
  min-height: 100vh;
  background: #fff;
}

.errorRoot {
  height: 100vh;
  display: flex;
  padding: 64px 8px;
  position: relative;
  background: #fff;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.errorRoot .errorHeader {
  margin: 0;
  font-size: 1.4rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.errorRoot .errorText {
  font-size: 1.2rem;
  font-family: inherit;
  font-weight: normal;
  line-height: 120%;
  letter-spacing: 0.01em;
  text-align: center;
}

.errorRoot .retryButton {
  color: #fff;
  width: 113px;
  height: 32px;
  margin-top: 12px;
  background-color: #000;
  padding: 6px 16px;
  border-radius: 4px;
  text-transform: uppercase;
  border: 0;
  font-family: inherit;
  font-weight: bold;
  cursor: pointer;
}

.react-swipeable-view-container > div {
  overflow: hidden !important;
}
